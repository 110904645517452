import React, { useContext } from "react"
import VizSensor from "react-visibility-sensor"

import { ScrollContext } from "../layout"

import variables from "../variables.scss"

const Highlight = ({ style }) => {
  const [, setFirstBlockVisibility] = useContext(ScrollContext)
  return (
    <VizSensor
      onChange={isVisible => setFirstBlockVisibility(isVisible)}
      partialVisibility
    >
      <div
        style={{
          minHeight: "200px",
          background: variables.yellow,
          alignItems: "flex-end",
          paddingLeft: "20px",
          paddingBottom: "32px",
          ...style,
        }}
      >
        <h1
          style={{
            fontSize: "32px",
            fontWeight: 400,
            fontFamily: '"Noto Serif", serif',
          }}
        >
          Rólunk
        </h1>
      </div>
    </VizSensor>
  )
}

export default Highlight
