import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Contact from "../components/common/contact"
import Relations from "../components/common/relations"
import Title from "../components/contact/title"

import { useWindowWidth } from "../services/hooks"

import variables from "../components/variables.scss"

const ContactPage = () => {
  const { pages } = useStaticQuery(graphql`
    query {
      pages {
        contact {
          title
          sections {
            title
            key
            blocks {
              title
              content
            }
          }
        }
      }
    }
  `)
  const { title, sections } = pages.contact
  const width = useWindowWidth()
  const isMobile = width <= parseInt(variables.mobile)
  return (
    <Layout>
      <SEO title={title} />

      <Title style={{ display: `${isMobile ? "flex" : "none"}` }} />
      {!isMobile && (
        <Contact
          section={sections.find(s => s.key === "contact")}
          mainSection={true}
        />
      )}
      <Relations section={sections.find(s => s.key === "relations")} />
      {isMobile && (
        <Contact
          section={sections.find(s => s.key === "contact")}
          hidePicture={true}
        />
      )}
    </Layout>
  )
}

export default ContactPage
